<template>
      <div class="map spad">
        <div class="container">
            <div class="map-inner">
                <iframe
                title="google map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.201152674967!2d36.8110401!3d-1.294348!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f11e9e9f172db%3A0xde236816f452452b!2sI%26M%20Bank%202nd%20Ngong%20Avenue%20Branch%2C%20Ngong%20Ave%2C%20Nairobi!5e0!3m2!1sen!2ske!4v1678351069397!5m2!1sen!2ske"
                    height="610" style="border:0" allowfullscreen="">
                </iframe>
                <div class="icon">
                    <i class="fa fa-map-marker"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>